import React from 'react';
import { Link } from "react-router-dom";
import floriscaLogo from "../images/florisca-logo.png";

class Navbar extends React.Component {

  render() {
    return (
      <nav className="navbar navbar-light navbar-expand-lg">
        <div className="navbar-brand">
          <Link to="/">
            <img src={floriscaLogo} alt="bloesem" height="80" className="mr-4"/>
          </Link>
          {/*  Florisca*/}
        </div>
        <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggler"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarToggler">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/">Welkom</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/Therapie">Therapie</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/Supervisie">Supervisie</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/OverPerlita">Over Perlita</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/Praktisch">Praktisch</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/Documenten">Documenten</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/Contact">Contact</Link>
            </li>
          </ul>
        </div>
      </nav>
    )
  }
}

export default Navbar;
