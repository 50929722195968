import React from 'react';
import {withBackgroundImg} from "../js/constants";

const Praktisch = () => (

  <div className="praktisch-page">
    <div className="with-bg-img" style={withBackgroundImg}>
      <div className="container">
        <h1 className="display-2">Praktisch</h1>
      </div>
    </div>
    <div className="container mt-4">

      <p className={"intro"}>Therapie en supervisie vinden online plaats middels videobellen.</p>

      <h5>Therapie</h5>

      <h6>Therapie via zorgverzekering</h6>

      <p>Therapie onder de paraplu van 1nP valt onder onder ‘gecontracteerde zorg’ en wordt hiermee vergoedt vanuit uw
        basisverzekering. U kunt wel een rekening van uw zorgverzekeraar ontvangen in verband met het eigen risico. De
        voorwaarden voor verzekerde zorg zijn 1) een verwijzing van een arts en 2) de behandelend psycholoog moet een
        DSM-5 classificatie kunnen stellen die onder de vergoede zorg valt.</p>

      <p>Voor meer informatie over bekostiging zie <a
        href={"https://www.1np.nl/praktische-info/vergoeding"} target={"_blank"}
        rel="noreferrer">https://www.1np.nl/praktische-info/vergoeding</a>. Wanneer
        er sprake is van verzekerde zorg, dient er binnen de
        intakefase een vragenlijst (HONOS+) te worden afgenomen. Deze vragenlijst nemen we samen door en uw antwoorden
        komen in uw dossier. De HONOS geeft zicht op de zorgvraagtypering, en is een verplichting vanuit de
        overheid.</p>

      <h6>Therapie zelf betalen</h6>

      <p>Valt uw therapie niet onder vergoede zorg omdat u bv. geen zorgverzekering heeft in Nederland, of omdat u om
        andere redenen zelf wil betalen, dan hanteer ik het NZa tarief voor “niet-basispakketzorg”. Voor berekening van
        het tarief kunt u contact met mij opnemen.</p>

      <h6>Aanmelden therapie</h6>

      <p>Wilt u zich aanmelden voor hulp via de zorgverzekeraar? Dat kan door een verwijsbrief van uw huisarts up te loaden op <a
          href={"https://public.extenzo.nu/#/professional-selectie/professional/13186"}
          target={"_blank"} rel="noreferrer">https://www.1np.nl/vind-een-behandelaar/behandelaar/-/wcl/F4C0fwDbBsu2/userid/27961619"
        </a>.</p>

      <p>1nP werkt met een kwaliteitsstatuut, dat u hier kan vinden <a
        href={"https://www.1np.nl/praktische-info/kwaliteit"}
        target={"_blank"} rel="noreferrer">https://www.1np.nl/praktische-info/kwaliteit</a>. U vindt informatie over de
        klachtenregeling van 1nP op <a
          href={"https://www.1np.nl/praktische-info/klantsignalen"}
          target={"_blank"} rel="noreferrer">https://www.1np.nl/praktische-info/klantsignalen</a>.</p>

      <h5>Info supervisie</h5>

      <p>Het supervisie-tarief bedraagt 110 euro per sessie. Een sessie duurt 45 minuten en kent 15 minuten
        voorbereidingstijd. Neem contact op via <a href="mailto:info@florisca.eu">info@florisca.eu</a> voor informatie
        over aanmelden en wachttijd.</p>

    </div>
  </div>
);

export default Praktisch;
